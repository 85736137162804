<template>
  <div
    v-if="this.eJSON.globalProps.logos.length"
    class="p-2 mb-4 d-flex justify-content-between shadow-sm position-relative"
    :style="{
      backgroundColor: '#242526',
      minHeight: '54px',
    }"
  >
    <img
      :src="$store.getters.environmentLogoURL"
      onerror="this.style.display='none'"
      class="mx-2"
      :style="{
        maxHeight: '40px',
        maxWidth: '100%',
        width: 'auto',
      }"
    />
    <b-button
      type="submit"
      variant="outline"
      class="position-absolute"
      :style="{
        color: 'white',
        borderColor: 'white',
        right: ' 10px !important',
      }"
      @click="signOut"
      >Sign Out</b-button
    >
  </div>
</template>

<script>
import { mapState } from "vuex"

export default {
  name: "Header",
  computed: {
    ...mapState({
      eJSON: (state) => state.eJSON,
      color: (state) => state.eJSON.globalProps.design?.colors,
      defaultColor: (state) => state.systemDesign.defaultColor,
      TopNavProp: (state) => state.eJSON.blockLibrary.TopNav.props,
    }),
  },
  methods: {
    async signOut() {
      await this.$apiPublic(
        "remember/it",
        {
          logType: "signOut",
          description: "user signed out",
          context: "session",
        },
        true,
      )
      await this.$store.commit("signOut")
    },
  },
}
</script>
