var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (this.eJSON.globalProps.logos.length)?_c('div',{staticClass:"p-2 mb-4 d-flex justify-content-between shadow-sm position-relative",style:({
    backgroundColor: '#242526',
    minHeight: '54px',
  })},[_c('img',{staticClass:"mx-2",style:({
      maxHeight: '40px',
      maxWidth: '100%',
      width: 'auto',
    }),attrs:{"src":_vm.$store.getters.environmentLogoURL,"onerror":"this.style.display='none'"}}),_c('b-button',{staticClass:"position-absolute",style:({
      color: 'white',
      borderColor: 'white',
      right: ' 10px !important',
    }),attrs:{"type":"submit","variant":"outline"},on:{"click":_vm.signOut}},[_vm._v("Sign Out")])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }